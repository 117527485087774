
function Footer() {
  return (
    <div class="absolute right-0 left-0 bottom-0 position-class">
      <footer className="bg-[#222] text-white text-center py-2">
        <div className="bottom-bar">
          All Rights Reserved
        </div>
      </footer>
    </div>
  );
}

export default Footer;
